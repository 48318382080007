import request from '@/utils/request'


// 查询引导页列表
export function listPage(query) {
  return request({
    url: '/biz/guide-page/list',
    method: 'get',
    params: query
  })
}

// 查询引导页分页
export function pagePage(query) {
  return request({
    url: '/biz/guide-page/page',
    method: 'get',
    params: query
  })
}

// 查询引导页详细
export function getPage(data) {
  return request({
    url: '/biz/guide-page/detail',
    method: 'get',
    params: data
  })
}

// 新增引导页
export function addPage(data) {
  return request({
    url: '/biz/guide-page/add',
    method: 'post',
    data: data
  })
}

// 修改引导页
export function updatePage(data) {
  return request({
    url: '/biz/guide-page/edit',
    method: 'post',
    data: data
  })
}

// 删除引导页
export function delPage(data) {
  return request({
    url: '/biz/guide-page/delete',
    method: 'post',
    data: data
  })
}
